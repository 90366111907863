import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html{
    height:100%
  }
  body {
    margin: 0;
    height:100%
}
    `;
    
export const StyledApp = styled.div`
  font-family: sans-serif;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  visibility: ${(props: { isLoading: boolean }) =>
    props.isLoading ? "hidden" : "unset"};
`;
export const MainContainer = styled.div`

  display: flex;
  margin: auto;
  flex-direction: column;

  img {
    width: 270px;
    margin: auto;
  }
`;
export const TitleContainer = styled.div`
  font-size: 28px;
  margin-block-start: 80px;
  font-family: 'Inter';
  text-align: center;
  line-height: 1.8;

  @media only screen and (max-width: 743px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 480px) {
    font-size: 1.3rem;
  }
`;
