/* eslint-disable */
import React from 'react';


interface SuccessBlockProps {
    serviceName: string;
}

const SuccessBlock: React.FC<SuccessBlockProps> = (props) => {
    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                Successfully connected to{' '}
                <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{props.serviceName}</span>
            </div>
            <div style={{ fontSize: '18px' }}>
                <b>Did you know?</b> Adding more connectors keeps the shadow out from your IT
                {/* Connecting more connectors helps you keep your security level higher. */}
            </div>
            {/* <div style={{ padding: 20 }} onClick={() => window.close()}>
                Close tab
            </div> */}
        </>
    );
}

export default SuccessBlock;
