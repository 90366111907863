/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    errorListItem: {
        margin: 0,
        textAlign: "left",
        fontSize: "0.7em",
    },
    errorListUl: {
      marginTop: "0.5rem",
    },
}));

const ErrorContainer = styled.div`
  text-align: center;
  display: inline-block;
`;

interface ErrorBlockProps {
    errorText: string;
    errorList: Array<string>;
    errorConclusion: string;
}

const ErrorBlock: React.FC<ErrorBlockProps> = (props) => {
    const classes = useStyles();

    let errorTitle = 'Unknown error occurred. Please contact the support.'
    if (props.errorText !== '') {
        errorTitle = props.errorText;
    }

    return (
        <ErrorContainer>
            <p>{errorTitle}</p>
            <ul className={classes.errorListUl}>
            {props.errorList.map((err,index)=>{
                return <li className={classes.errorListItem} key={index}>{err}</li>
            })}
            </ul>
            <p>Please close this tab and re-connect with all scopes...</p>
        </ErrorContainer>
    );
}

export default ErrorBlock;
