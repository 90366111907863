import { Fragment } from 'react';
import { GlobalStyle, MainContainer, StyledApp, TitleContainer } from './App.styles';
import logo from './assets/wing-new-logo.svg';
import ErrorBlock from "./components/ErrorBlock/ErrorBlock";
import SuccessBlock from "./components/SuccessBlock/SuccessBlock";
import './assets/fonts/fonts.css';

// App is responsible for reflecting not only success pages but error pages as well
// If errors passed as a URL parameter - the error/s will be rendered
function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const stateParam = urlParams.get('state');
  const errors = urlParams.get('errors');
  let isErrorPage = false
  let errorText = ''
  let errorsList = []
  let errorConclusion = ''
  let serviceName = 'wing';

  if (stateParam) {
    const decodedState = atob(stateParam);
    try {
      const jsonParsed = JSON.parse(decodedState);
      const jsonService = jsonParsed.service;
      if (jsonService) {
        serviceName = jsonService;
      }
    } catch (e) {
      console.log(e);
    }
  }
  if (errors) {
    isErrorPage = true
    try {
      const decodedErrors = atob(errors);
      const jsonParsed = JSON.parse(decodedErrors);
      errorText = jsonParsed.errorText;
      errorsList = jsonParsed.errorsList;
      errorConclusion = jsonParsed.errorConclusion;
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Fragment>
      <GlobalStyle />
      <StyledApp isLoading={false}>
        <MainContainer>
          <img src={logo} alt={'wing logo'} />
          <TitleContainer>
          {!isErrorPage && <SuccessBlock serviceName={serviceName} />}
          {isErrorPage && <ErrorBlock errorText={errorText} errorList={errorsList} errorConclusion={errorConclusion} />}
          </TitleContainer>
        </MainContainer>
      </StyledApp>
    </Fragment>
  );
}

export default App;
